// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-card.mat-card {
  box-shadow:0 2px 1px -1px rgba(55,55,120,.8), 0 1px 1px 0 rgba(55,55,120,.8), 0 1px 3px 0 rgba(55,55,120,.8);
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/submittal/build-submittal/firestop-card/joint-card/joint-card.component.css"],"names":[],"mappings":"AAAA;EACE,4GAA4G;AAC9G","sourcesContent":["mat-card.mat-card {\n  box-shadow:0 2px 1px -1px rgba(55,55,120,.8), 0 1px 1px 0 rgba(55,55,120,.8), 0 1px 3px 0 rgba(55,55,120,.8);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
