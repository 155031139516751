import {Component, OnInit} from '@angular/core';
import {PageService} from '../../services/page.service';
import {Hero, Page, SiteInfo} from '../../models';
import {HeroService} from '../../services/hero.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SiteInfoService} from '../../services/site-info.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  public page: Page;
  public siteInfo: SiteInfo;
  public loaded = false;
  public language = 'en';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private pageService: PageService,
    private siteInfoService: SiteInfoService,
  ) {
    // @ts-ignore
    router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationEnd) {
        this.loadPage();
      }
    });
  }

  public loadPage() {
    this.siteInfoService.list({lang: this.language}).subscribe(info => {
      this.siteInfo = info[0];
    });
    this.pageService.get('home', {lang: this.language}).subscribe(page => {
      this.page = page;
    });
    this.route.url.subscribe(url => {
      this.language = url[0].path;
    });
  }

  ngOnInit() {
    this.loadPage();
  }

}
