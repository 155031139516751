import {Injectable } from '@angular/core';
import {IoService} from './io.service';
import {Submittal} from '../models';

@Injectable({
  providedIn: 'root'
})
export class SubmittalService extends IoService {
  protected model = Submittal;
  protected listUrl = '/submittal/';
}
