import { Component, OnInit } from '@angular/core';
import {TechDocService} from '../../services/tech-doc.service';
import {TechDocTypeService} from '../../services/tech-doc-type.service';
import {TechDoc, TechDocType, TechDocZipRequest} from '../../models';
import {forkJoin} from 'rxjs';
import {IoService} from '../../services/io.service';
import {TechDocZipRequestService} from '../../services/tech-doc-zip-request.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-resource',
  templateUrl: './resource.component.html',
  styleUrls: ['./resource.component.css']
})
export class ResourceComponent implements OnInit {
  public loading = true;
  public techDocTypes: TechDocType[] = [];
  public techDocs: TechDoc[] = [];
  public selectedDocTypes = [];
  public exporting = false;

  constructor(private techDocService: TechDocService,
              private techDocTypeService: TechDocTypeService,
              private techDocZipRequestService: TechDocZipRequestService,
              private snackbar: MatSnackBar) { }

  public filterDocType() {
    this.loading = true;
    this.techDocService.list({doc_types: this.selectedDocTypes}).subscribe((docs: TechDoc[]) => {
      this.techDocs = docs;
      this.loading = false;
    });
  }

  public getDocTypeName(doc: TechDoc) {
    const id = Number(IoService.getIdFromUrl(doc.doc_type));
    return this.techDocTypes.filter(t => t.id === id)[0].name;
  }

  public exportResources() {
    const zipRequest = new TechDocZipRequest();
    zipRequest.tech_docs = this.techDocs.map(t => t.url);
    if (zipRequest.tech_docs.length === 0) {
      this.snackbar.open('No resources to export. Refine your filters.', 'Dismiss', {
        duration: 5000,
      });
      return;
    }
    this.exporting = true;
    this.techDocZipRequestService.create(zipRequest).subscribe((newZipRequest: TechDocZipRequest) => {
      setTimeout(() => {
        this.checkOnExport(newZipRequest.id);
      }, 1000);
    });
  }

  /**
   * Check if the zip is ready, or threw an error. If neither, check again in a bit
   * @param id of the zip request
   */
  public checkOnExport(id: number) {
    this.techDocZipRequestService.get(id.toString()).subscribe((zipRequest: TechDocZipRequest) => {
      if (zipRequest.file) {
        this.exporting = false;
        window.location.href = zipRequest.file;
      } else if (zipRequest.error) {
        this.exporting = false;
        this.snackbar.open('An error has occurred. Please try again.', 'Dismiss');
      } else {
        setTimeout(() => {
          this.checkOnExport(id);
        }, 1000);
      }
    });
  }

  public loadPage() {
    this.loading = true;
    forkJoin(
      this.techDocTypeService.list(),
      this.techDocService.list(),
    ).subscribe(([docTypes, docs]) => {
      this.techDocTypes = docTypes;
      this.techDocs = docs;
      this.loading = false;
    });
  }

  ngOnInit() {
    this.loadPage();
  }

}
