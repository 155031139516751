// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filters {
  margin:1em 0;
}
.firestop-chooser {
  width:100%;
  padding:0;
  margin:0;
}

.filters mat-button-toggle-group {
  margin-right:1rem;
}
.expanders {
  display:inline-block;
  margin-top:0.5rem;
}
.expanders > button:first-child {
  width:14rem;
}
.expanders > button:last-child {
  width:10rem;
}

@media only screen and (min-width:30em) {
  .filters {
    padding:1em;
    border:1px solid #ccc;
    border-radius:5px;
  }
}

@media only screen and (min-width:50em) {
  .mobile-submittal {
    display:none;
  }
  .mat-button-toggle {
    width:auto;
  }
  .filters {
    text-align:left;
  }
}

@media only screen and (min-width:80em) {
  .expanders {float:right;}
}

`, "",{"version":3,"sources":["webpack://./src/app/pages/submittal/build-submittal/build-submittal.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,UAAU;EACV,SAAS;EACT,QAAQ;AACV;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,oBAAoB;EACpB,iBAAiB;AACnB;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb;;AAEA;EACE;IACE,WAAW;IACX,qBAAqB;IACrB,iBAAiB;EACnB;AACF;;AAEA;EACE;IACE,YAAY;EACd;EACA;IACE,UAAU;EACZ;EACA;IACE,eAAe;EACjB;AACF;;AAEA;EACE,YAAY,WAAW,CAAC;AAC1B","sourcesContent":[".filters {\n  margin:1em 0;\n}\n.firestop-chooser {\n  width:100%;\n  padding:0;\n  margin:0;\n}\n\n.filters mat-button-toggle-group {\n  margin-right:1rem;\n}\n.expanders {\n  display:inline-block;\n  margin-top:0.5rem;\n}\n.expanders > button:first-child {\n  width:14rem;\n}\n.expanders > button:last-child {\n  width:10rem;\n}\n\n@media only screen and (min-width:30em) {\n  .filters {\n    padding:1em;\n    border:1px solid #ccc;\n    border-radius:5px;\n  }\n}\n\n@media only screen and (min-width:50em) {\n  .mobile-submittal {\n    display:none;\n  }\n  .mat-button-toggle {\n    width:auto;\n  }\n  .filters {\n    text-align:left;\n  }\n}\n\n@media only screen and (min-width:80em) {\n  .expanders {float:right;}\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
