import {Injectable} from '@angular/core';
import {IoService} from './io.service';
import {Penetration} from '../models';


@Injectable()
export class PenetrationService extends IoService {
  protected model = Penetration;
  protected listUrl = '/penetrations/';
}
