import {Component, Input} from '@angular/core';
import {Product} from '../../../models';
import {Router} from '@angular/router';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.css']
})
export class ProductCardComponent {
  @Input() language = 'en';
  @Input() product: Product;
  @Input() color: string = '#000000dd';
  public hover = false;
  constructor(
    private router: Router,
  ) {}

  public goToProduct() {
    this.router.navigate(['/', this.language, 'products', this.product.id]);
  }
}
