import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {SearchService} from '../../services/search.service';
import {Joint, Page, Penetration, Product, SiteInfo} from '../../models';
import {SiteInfoService} from '../../services/site-info.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent {
  public language = 'en';
  public query = '';
  public products: Product[] = [];
  public joints: Joint[] = [];
  public penetrations: Penetration[] = [];
  public pages: Page[] = [];
  private models = ['product', 'joint', 'penetration', 'page'];
  public searching = false;
  public tabIndex = 0;
  public siteInfo: SiteInfo;
  public loaded = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private searchService: SearchService,
    private siteInfoService: SiteInfoService,
  ) {
    // @ts-ignore
    router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationStart) {
        this.loaded = false;
      }
      if (event instanceof NavigationEnd) {
        this.loadPage();
      }
    });
  }

  public loadPage() {
    this.route.url.subscribe(url => {
      this.language = url[0].path;
      this.siteInfoService.list({lang: this.language}).subscribe(info => {
        this.siteInfo = info[0];
        this.route.queryParams.subscribe(params => {
          this.query = params['q'];
          this.search();
        });
      });
    });
  }

  public search() {
    if (this.query && this.query.trim() !== '') {
      this.searching = true;
      this.tabIndex = 0;
      this.searchService.search(this.language, this.query, this.models).subscribe((results: Array<any>) => {
        this.products = results[0];
        this.joints = results[1];
        this.penetrations = results[2];
        this.pages = results[3];

        if (this.products.length !== 0) {
          this.tabIndex = 0;
        } else if (this.products.length === 0 && this.joints.length !== 0) {
          this.tabIndex = 1;
        } else if (this.joints.length === 0 && this.penetrations.length !== 0) {
          this.tabIndex = 2;
        } else if (this.penetrations.length === 0 && this.pages.length !== 0) {
          this.tabIndex = 3;
        }
      });
      this.searching = false;
      this.loaded = true;
    }
  }
}
