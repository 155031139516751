import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Submittal} from '../../../models';
import {LocalStorageService} from '../../../services/local-storage.service';

@Component({
  selector: 'app-submittal-view',
  templateUrl: './submittal-view.component.html',
  styleUrls: ['./submittal-view.component.css']
})
export class SubmittalViewComponent {
  @Input() localSubmittal: Submittal;
  @Output() clear = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();

  constructor(
    private storage: LocalStorageService,
  ) {
  }

  public delete(item: any) {
    this.remove.emit(item);
  }

  public clearSubmittal() {
    this.clear.emit(true);
  }
}
