import {Component, Input, OnInit} from '@angular/core';
import {Joint, Submittal} from '../../../../../models';
import {LocalStorageService} from '../../../../../services/local-storage.service';

@Component({
  selector: 'app-joint-card',
  templateUrl: './joint-card.component.html',
  styleUrls: ['../firestop-card.component.css', './joint-card.component.css']
})
export class JointCardComponent implements OnInit {
  @Input() language = 'en';
  @Input() joint: Joint;
  public localSubmittal: Submittal;
  public inLocal = false;
  @Input() showDetails = false;

  constructor(
    private storage: LocalStorageService
  ) { }

  public checkJoint() {
    this.inLocal = (this.localSubmittal.joints.filter(j => j.url === this.joint.url).length > 0);
  }

  public toggleDetails() {
    this.showDetails = !this.showDetails;
  }
  public addJoint() {
    this.localSubmittal.joints.push(this.joint);
    this.storage.set('submittal', this.localSubmittal);
    this.inLocal = true;
  }

  public removeJoint() {
    this.localSubmittal.joints = this.localSubmittal.joints.filter(j => j.url !== this.joint.url);
    this.storage.set('submittal', this.localSubmittal);
    this.inLocal = false;
  }

  ngOnInit() {
    this.storage.select('submittal').subscribe(local => {
      if (local) {
        this.localSubmittal = local;
      } else {
        this.localSubmittal = new Submittal();
        this.storage.set('submittal', this.localSubmittal);
      }
      this.checkJoint();
    });
  }
}
