import {Component, Input, OnInit} from '@angular/core';
import {Joint, Penetration} from '../../../models';
import {JointService} from '../../../services/joint.service';
import {PenetrationService} from '../../../services/penetration.service';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';

@Component({
  selector: 'app-build-submittal',
  templateUrl: './build-submittal.component.html',
  styleUrls: ['./build-submittal.component.css']
})

export class BuildSubmittalComponent implements OnInit {
  @Input() language = 'en';
  public joints: Joint[] = Array();
  public penetrations: Penetration[] = Array();
  public loading = true;
  public filter = 'penetrations';
  public show = true;
  public params: any;
  public expandAll = true;

  constructor(
    private jointService: JointService,
    private penetrationService: PenetrationService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    // @ts-ignore
    router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationStart) {
        this.loading = true;
      }
      if (event instanceof NavigationEnd) {
        this.loadPage();
      }
    });
  }

  public toggleFilters() {
    this.show = !this.show;
  }
  public toggleExpandAll() {
    this.expandAll = !this.expandAll;
  }

  public pickJoints() {
    this.loading = true;
    this.filter = 'joints';
    this.getFirestops(this.params);
  }
  public pickPenetrations() {
    this.loading = true;
    this.filter = 'penetrations';
    this.getFirestops(this.params);
  }

  public getFirestops(params) {
    this.loading = true;
    this.joints = Array();
    this.penetrations = Array();
    if (this.filter === 'joints') {
      this.jointService.list(params).subscribe(joints => {
        this.joints = joints;
        this.loading = false;
      });
    } else {
      this.penetrationService.list(params).subscribe(penetrations => {
        this.penetrations = penetrations;
        this.loading = false;
      });
    }
  }

  public loadPage() {
    this.route.url.subscribe(url => {
      this.language = url[0].path;
      this.params = {lang: this.language};
      this.getFirestops(this.params);
    });
  }

  ngOnInit() {
    this.loadPage();
  }
}

