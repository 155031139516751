// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-radio-button {
  padding-right:1.25em;
}
.column {
  padding:1em;
  display:inline-block;
  vertical-align:top;
  width:100%;
}

mat-slider {
  width:100%;
}

.full .size {
  display:inline-block;
  width:2rem;
}
.mat-mdc-mini-fab.mat-accent {
  background:#1d1d1e;
  color:#eee;
}

.column mat-form-field {
  display:inline-block;
  width:100%;
}
.controls {
  text-align:right;
}

.controls button {
  margin:0 0 0 1em;
}
p {
  padding:1em 0;
}

@media only screen and (min-width:50em) {
  .column:first-child {
    width:65%;
    padding:0.5em 1em;
  }
  .column:nth-child(2) {
    width:33%;

  }
  .full {
    width:100%;
    padding:0.5em 1em;
    display:block;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/submittal/build-submittal/firestop-filters/firestop-filters.component.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;AACA;EACE,WAAW;EACX,oBAAoB;EACpB,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,oBAAoB;EACpB,UAAU;AACZ;AACA;EACE,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,oBAAoB;EACpB,UAAU;AACZ;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;;AAEA;EACE;IACE,SAAS;IACT,iBAAiB;EACnB;EACA;IACE,SAAS;;EAEX;EACA;IACE,UAAU;IACV,iBAAiB;IACjB,aAAa;EACf;AACF","sourcesContent":["mat-radio-button {\n  padding-right:1.25em;\n}\n.column {\n  padding:1em;\n  display:inline-block;\n  vertical-align:top;\n  width:100%;\n}\n\nmat-slider {\n  width:100%;\n}\n\n.full .size {\n  display:inline-block;\n  width:2rem;\n}\n.mat-mdc-mini-fab.mat-accent {\n  background:#1d1d1e;\n  color:#eee;\n}\n\n.column mat-form-field {\n  display:inline-block;\n  width:100%;\n}\n.controls {\n  text-align:right;\n}\n\n.controls button {\n  margin:0 0 0 1em;\n}\np {\n  padding:1em 0;\n}\n\n@media only screen and (min-width:50em) {\n  .column:first-child {\n    width:65%;\n    padding:0.5em 1em;\n  }\n  .column:nth-child(2) {\n    width:33%;\n\n  }\n  .full {\n    width:100%;\n    padding:0.5em 1em;\n    display:block;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
