import {Component, EventEmitter, Input, OnInit, Output, Pipe} from '@angular/core';
import {Assembly, JointType, Penetrant, PipeCover, Sleeve, Substrate} from '../../../../models';
import {forkJoin} from 'rxjs';
import {JointtypeService} from '../../../../services/jointtype.service';
import {SubstrateService} from '../../../../services/substrate.service';
import {AssemblyService} from '../../../../services/assembly.service';
import {PenetrantService} from '../../../../services/penetrant.service';
import {SleeveService} from '../../../../services/sleeve.service';
import {PipeCoverService} from '../../../../services/pipe-cover.service';

@Component({
  selector: 'app-firestop-filters',
  templateUrl: './firestop-filters.component.html',
  styleUrls: ['./firestop-filters.component.css']
})
export class FirestopFiltersComponent implements OnInit {
  @Input() language = 'en';
  @Input() filter = 'joints';
  @Output() params = new EventEmitter<any>();
  // Common firestop filter stuff
  public fRatings: number[] = Array();
  public fireResistance: number = null;
  public tRatings: number[] = Array();
  public thermalResistance: number = null;
  public pressureTested: boolean = null;
  // Joint related filter stuff
  public substrates: Substrate[] = Array();
  public jointTypes: JointType[] = Array();
  public substrateSelected: Substrate;
  public jtSelected: JointType;
  public jointWidth = 0;
  // Penetration related filter stuff
  public assemblies: Assembly[] = Array();
  public assemblySelected: Assembly;
  public penetrants: Penetrant[] = Array();
  public penetrantSelected: Penetrant;
  public sleeves: Sleeve[] = Array();
  public sleeveSelected: Sleeve;
  public pipeCovers: PipeCover[] = Array();
  public pipeCoverSelected: PipeCover;
  public annularSpace = 0;
  public nominalPenetrant = 0;
  constructor(
    private jtService: JointtypeService,
    private substrateService: SubstrateService,
    private assemblyService: AssemblyService,
    private penetrantService: PenetrantService,
    private sleeveService: SleeveService,
    private pipeCoverService: PipeCoverService,
  ) { }

  public resetFilters() {
    this.fireResistance = null;
    this.thermalResistance = null;
    this.pressureTested = null;
    this.substrateSelected = null;
    this.jtSelected = null;
    this.jointWidth = 0;
    this.assemblySelected = null;
    this.penetrantSelected = null;
    this.sleeveSelected = null;
    this.pipeCoverSelected = null;
    this.annularSpace = 0;
    this.nominalPenetrant = 0;
    this.buildParams();
  }

  public setNull(selection: any) {
    selection = null;
  }

  public getOptions() {
    const params = {lang: this.language};
    forkJoin(
      this.jtService.list(params),
      this.substrateService.list(params),
      this.assemblyService.list(params),
      this.penetrantService.list(params),
      this.sleeveService.list(params),
      this.pipeCoverService.list(params),
    ).subscribe(results => {
      this.jointTypes = results[0];
      this.substrates = results[1];
      this.assemblies = results[2];
      this.penetrants = results[3];
      this.sleeves = results[4];
      this.pipeCovers = results[5];
    });
    this.fRatings = [0, 1, 2, 3];
    this.tRatings = [0, 1, 2, 3];

  }

  public buildParams() {
    const params: Record<string, any> = {};
    params.lang = this.language;
    if (this.fireResistance !== null) {
      params.fire = this.fireResistance;
    }
    if (this.thermalResistance !== null) {
      params.thermal = this.thermalResistance;
    }
    if (this.pressureTested !== null) {
      params.pressure = this.pressureTested;
    }
    if (this.filter === 'joints') {
      if (this.jointWidth !== 0) {
        params.width = this.jointWidth;
      }
      if (this.jtSelected != null) {
        params.type = this.jtSelected.id;
      }
      if (this.substrateSelected != null) {
        params.substrate = this.substrateSelected.id;
      }
    } else {
      if (this.assemblySelected != null) {
        params.assembly = this.assemblySelected.id;
      }
      if (this.penetrantSelected != null) {
        params.penetrant = this.penetrantSelected.id;
      }
      if (this.sleeveSelected != null) {
        params.sleeve = this.sleeveSelected.id;
      }
      if (this.pipeCoverSelected != null) {
        params.pipecover = this.pipeCoverSelected.id;
      }
      if (this.annularSpace !== 0) {
        params.annular = this.annularSpace;
      }
      if (this.nominalPenetrant !== 0 ) {
        params.nominal = this.nominalPenetrant;
      }
    }
    this.params.emit(params);
  }

  ngOnInit() {
    this.getOptions();
  }
}
