import {Component, Input} from '@angular/core';
import {Submittal} from '../../../models';
import {LocalStorageService} from '../../../services/local-storage.service';

@Component({
  selector: 'app-info-submittal',
  templateUrl: './info-submittal.component.html',
  styleUrls: ['./info-submittal.component.css']
})
export class InfoSubmittalComponent {
  @Input() language = 'en';
  @Input() localSubmittal: Submittal;

  constructor(
    private storage: LocalStorageService,
  ) { }

  save() {
    this.storage.set('submittal', this.localSubmittal);
  }
}
