// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-card-title, mat-card-subtitle {
  text-align:center;
}

img {
  height:22em;
  width:auto;
  display:inline-block;
}
.image {
  text-align:center;
}
h2 {font-size:1em; display:inline-block; min-height:6em;}
h3 {font-size:1.2em;}
h4 {font-size:1.2em;}
`, "",{"version":3,"sources":["webpack://./src/app/pages/catalogue/product-card/product-card.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,UAAU;EACV,oBAAoB;AACtB;AACA;EACE,iBAAiB;AACnB;AACA,IAAI,aAAa,EAAE,oBAAoB,EAAE,cAAc,CAAC;AACxD,IAAI,eAAe,CAAC;AACpB,IAAI,eAAe,CAAC","sourcesContent":["mat-card-title, mat-card-subtitle {\n  text-align:center;\n}\n\nimg {\n  height:22em;\n  width:auto;\n  display:inline-block;\n}\n.image {\n  text-align:center;\n}\nh2 {font-size:1em; display:inline-block; min-height:6em;}\nh3 {font-size:1.2em;}\nh4 {font-size:1.2em;}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
