import {Component, Input, OnInit} from '@angular/core';
import {Penetration, Submittal} from '../../../../../models';
import {LocalStorageService} from '../../../../../services/local-storage.service';

@Component({
  selector: 'app-penetration-card',
  templateUrl: './penetration-card.component.html',
  styleUrls: ['../firestop-card.component.css', './penetration-card.component.css']
})
export class PenetrationCardComponent implements OnInit {
  @Input() language = 'en';
  @Input() penetration: Penetration;
  public localSubmittal: Submittal;
  public inLocal = false;
  @Input() showDetails = false;

  constructor(
    private storage: LocalStorageService,
  ) { }

  public checkPenetration() {
    this.inLocal = (this.localSubmittal.penetrations.filter(p => p.url === this.penetration.url).length > 0);
  }

  public toggleDetails() {
    this.showDetails = !this.showDetails;
  }

  public addPenetration() {
    this.localSubmittal.penetrations.push(this.penetration);
    this.storage.set('submittal', this.localSubmittal);
    this.inLocal = true;
  }

  public removePenetration() {
    this.localSubmittal.penetrations = this.localSubmittal.penetrations.filter(p => p.url !== this.penetration.url);
    this.storage.set('submittal', this.localSubmittal);
    this.inLocal = false;
  }

  ngOnInit() {
    this.storage.select('submittal').subscribe(local => {
      if (local) {
        this.localSubmittal = local;
      } else {
        this.localSubmittal = new Submittal();
        this.storage.set('submittal', this.localSubmittal);
      }
      this.checkPenetration();
    });
  }
}
