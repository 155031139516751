// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.local-submittal {
  min-height:15em;
}
button {
  float:right;
}
mat-list {
  background:white;
  padding:0;
}
mat-list-item .hover {
  display:none;
}
mat-list-item:hover {
  background:#fee;
}
mat-list-item:hover .hover {
  display:inline;
}
mat-list-item:hover .del {
  display:none;
}
mat-icon {float:right;}

.clear {
  margin-top:1rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/submittal/submittal-view/submittal-view.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,SAAS;AACX;AACA;EACE,YAAY;AACd;AACA;EACE,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;EACE,YAAY;AACd;AACA,UAAU,WAAW,CAAC;;AAEtB;EACE,eAAe;AACjB","sourcesContent":[".local-submittal {\n  min-height:15em;\n}\nbutton {\n  float:right;\n}\nmat-list {\n  background:white;\n  padding:0;\n}\nmat-list-item .hover {\n  display:none;\n}\nmat-list-item:hover {\n  background:#fee;\n}\nmat-list-item:hover .hover {\n  display:inline;\n}\nmat-list-item:hover .del {\n  display:none;\n}\nmat-icon {float:right;}\n\n.clear {\n  margin-top:1rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
