class HttpOptionBase {
  public constructor(data: {}) {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  }
}

export class HttpOption extends HttpOptionBase {
  name: string;
  description: string;
  parses: string[];
  fields: {[key: string]: HttpOptionField[]};

  public constructor(data: {}) {
    if (data.hasOwnProperty('actions') && data['actions'].hasOwnProperty('PUT')) {
      data['fields'] = data['actions']['PUT'];
      delete data['actions'];
      Object.keys(data['fields']).forEach(key => {
        data['fields'][key] = new HttpOptionField(data['fields'][key]);
      });
    }
    super(data);
  }
}

export class HttpOptionField extends HttpOptionBase {
  key: string;
  type: string;
  required: boolean;
  read_only: boolean;
  label: string;
  max_length: number;
  choices: HttpOptionFieldChoice[];

  public constructor(data: {}) {
    if (data.hasOwnProperty('choices')) {
      for (let i = 0; i < data['choices'].length; i++) {
        data['choices'][i] = new HttpOptionFieldChoice(data['choices'][i]);
      }
    }
    super(data);
  }
}

export class HttpOptionFieldChoice extends HttpOptionBase {
  value: number|string;
  display_name: number|string;
}
