// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.language {
  display:inline-block;
}
a, strong {
  padding:0.75em 1.5em;
  display:inline-block;
  border:2px solid #fff;
  border-radius:10px;
  margin:0 0.25em 0.5em;
}
a {
  background:#000;
  color:#fff;
  text-decoration:none;
}
strong {
  background:#fff;
  color:#000;
}

a:focus, a:hover {
  cursor:pointer;
  text-decoration:underline;
  background:#fff;
  color:#000;
}

@media only screen and (min-width:50em) {
  a, strong {
    padding:0.2em 0.5em;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/components/i18n/i18n.component.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;AACtB;AACA;EACE,oBAAoB;EACpB,oBAAoB;EACpB,qBAAqB;EACrB,kBAAkB;EAClB,qBAAqB;AACvB;AACA;EACE,eAAe;EACf,UAAU;EACV,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,UAAU;AACZ;;AAEA;EACE,cAAc;EACd,yBAAyB;EACzB,eAAe;EACf,UAAU;AACZ;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".language {\n  display:inline-block;\n}\na, strong {\n  padding:0.75em 1.5em;\n  display:inline-block;\n  border:2px solid #fff;\n  border-radius:10px;\n  margin:0 0.25em 0.5em;\n}\na {\n  background:#000;\n  color:#fff;\n  text-decoration:none;\n}\nstrong {\n  background:#fff;\n  color:#000;\n}\n\na:focus, a:hover {\n  cursor:pointer;\n  text-decoration:underline;\n  background:#fff;\n  color:#000;\n}\n\n@media only screen and (min-width:50em) {\n  a, strong {\n    padding:0.2em 0.5em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
