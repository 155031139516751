// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  padding:2em 3em;
}

section {
  padding:1em 0;
  vertical-align:top;
}
p img {
  max-width:100%;
  height:auto;
}

iframe {
  max-width:100%;
  height:auto;
}

.flexy {
  display:flex;
  justify-content: space-around;
  gap:1rem;
}

.main {
  flex:2;
  order:2;
}
.sidebar {
  flex:1;
  margin-top:3rem;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/content-page/content-page.component.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;AACA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,cAAc;EACd,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,QAAQ;AACV;;AAEA;EACE,MAAM;EACN,OAAO;AACT;AACA;EACE,MAAM;EACN,eAAe;AACjB","sourcesContent":[".content {\n  padding:2em 3em;\n}\n\nsection {\n  padding:1em 0;\n  vertical-align:top;\n}\np img {\n  max-width:100%;\n  height:auto;\n}\n\niframe {\n  max-width:100%;\n  height:auto;\n}\n\n.flexy {\n  display:flex;\n  justify-content: space-around;\n  gap:1rem;\n}\n\n.main {\n  flex:2;\n  order:2;\n}\n.sidebar {\n  flex:1;\n  margin-top:3rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
