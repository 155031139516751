import {BrowserModule} from '@angular/platform-browser';
import {NgModule, SecurityContext} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MarkdownModule} from 'ngx-markdown';

// Services
import {SiteInfoService} from './services/site-info.service';

// Material Imports
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatBadgeModule} from "@angular/material/badge";
import {MatButtonModule} from "@angular/material/button";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatRadioModule} from "@angular/material/radio";
import {MatCardModule} from "@angular/material/card";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatChipsModule} from "@angular/material/chips";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {MatSliderModule} from "@angular/material/slider";
import {MatStepperModule} from "@angular/material/stepper";
import {MatTableModule} from "@angular/material/table";
import {MatTabsModule} from "@angular/material/tabs";
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {HomeComponent} from './pages/home/home.component';
import {AssemblyService} from './services/assembly.service';
import {CallToActionService} from './services/call-to-action.service';
import {CategoryService} from './services/category.service';
import {ColumnService} from './services/column.service';
import {ContentBlockService} from './services/content-block.service';
import {FeatureService} from './services/feature.service';
import {FirestopTagService} from './services/firestop-tag.service';
import {HeroService} from './services/hero.service';
import {IoService} from './services/io.service';
import {JointService} from './services/joint.service';
import {JointtypeService} from './services/jointtype.service';
import {MediaService} from './services/media.service';
import {PageService} from './services/page.service';
import {PenetrantService} from './services/penetrant.service';
import {PagerService} from './services/pager.service';
import {PenetrationService} from './services/penetration.service';
import {ProductService} from './services/product.service';
import {ProductTagService} from './services/product-tag.service';
import {SleeveService} from './services/sleeve.service';
import {SubcategoryService} from './services/subcategory.service';
import {SubstrateService} from './services/substrate.service';
import {TechDocService} from './services/tech-doc.service';
import {TechDocTypeService} from './services/tech-doc-type.service';
import {HttpClientModule} from '@angular/common/http';
import {CatalogueComponent} from './pages/catalogue/catalogue.component';
import {ProductComponent} from './pages/product/product.component';
import {I18nComponent} from './components/i18n/i18n.component';
import {BasicPageComponent} from './components/basic-page/basic-page.component';
import {ProductCardComponent} from './pages/catalogue/product-card/product-card.component';
import {FormsModule} from '@angular/forms';
import {SearchComponent} from './pages/search/search.component';
import {SubmittalComponent} from './pages/submittal/submittal.component';
import {BuildSubmittalComponent} from './pages/submittal/build-submittal/build-submittal.component';
import {InfoSubmittalComponent} from './pages/submittal/info-submittal/info-submittal.component';
import {GetSubmittalComponent} from './pages/submittal/get-submittal/get-submittal.component';
import {JointCardComponent} from './pages/submittal/build-submittal/firestop-card/joint-card/joint-card.component';
import {
    PenetrationCardComponent
} from './pages/submittal/build-submittal/firestop-card/penetration-card/penetration-card.component';
import {PageCardComponent} from './components/page-card/page-card.component';
import {ContentPageComponent} from './pages/content-page/content-page.component';
import {FirestopFiltersComponent} from './pages/submittal/build-submittal/firestop-filters/firestop-filters.component';
import {SubmittalViewComponent} from './pages/submittal/submittal-view/submittal-view.component';
import {MobileSubmittalViewComponent} from './pages/submittal/mobile-submittal-view/mobile-submittal-view.component';
import {PipeCoverService} from './services/pipe-cover.service';
import {ResourceComponent} from './pages/resource/resource.component';
import {TechDocZipRequestService} from './services/tech-doc-zip-request.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatListModule} from "@angular/material/list";

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        CatalogueComponent,
        ProductComponent,
        I18nComponent,
        BasicPageComponent,
        ProductCardComponent,
        SearchComponent,
        SubmittalComponent,
        BuildSubmittalComponent,
        InfoSubmittalComponent,
        GetSubmittalComponent,
        JointCardComponent,
        PenetrationCardComponent,
        PageCardComponent,
        ContentPageComponent,
        FirestopFiltersComponent,
        SubmittalViewComponent,
        MobileSubmittalViewComponent,
        ResourceComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        MarkdownModule.forRoot({
            sanitize: SecurityContext.NONE
        }),
        MatToolbarModule,
        MatFormFieldModule,
        MatIconModule,
        MatExpansionModule,
        MatInputModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        FormsModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatTabsModule,
        MatBadgeModule,
        MatStepperModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatRadioModule,
        MatSliderModule,
        MatSelectModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatBottomSheetModule,
        MatSnackBarModule,
        MatListModule,
    ],
    providers: [
        AssemblyService,
        CallToActionService,
        CategoryService,
        ColumnService,
        ContentBlockService,
        FeatureService,
        FirestopTagService,
        HeroService,
        IoService,
        JointService,
        JointtypeService,
        MediaService,
        PageService,
        PagerService,
        PenetrantService,
        PenetrationService,
        ProductService,
        ProductTagService,
        SiteInfoService,
        SleeveService,
        PipeCoverService,
        SubcategoryService,
        SubstrateService,
        TechDocService,
        TechDocTypeService,
        TechDocZipRequestService,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
