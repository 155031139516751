import {Component, Input} from '@angular/core';
import {LocalStorageService} from '../../../services/local-storage.service';
import {Submittal} from '../../../models';
import {SubmittalService} from '../../../services/submittal.service';

@Component({
  selector: 'app-get-submittal',
  templateUrl: './get-submittal.component.html',
  styleUrls: ['./get-submittal.component.css']
})
export class GetSubmittalComponent {
  @Input() language = 'en';
  @Input() localSubmittal: Submittal;

  constructor(
    private storage: LocalStorageService,
    private submittalService: SubmittalService,
  ) { }

  isValidSubmittal() {
    return this.localSubmittal.joints.length > 0 || this.localSubmittal.penetrations.length > 0;
  }

  public getNow() {
    for (const joint of this.localSubmittal.joints) {
      this.localSubmittal.joint_ids.push(joint.url);
    }
    for (const penetration of this.localSubmittal.penetrations) {
      this.localSubmittal.penetration_ids.push(penetration.url);
    }
    this.submittalService.create(this.localSubmittal).subscribe(sub => {
      this.localSubmittal = sub;
      this.storage.set('submittal', this.localSubmittal);
    });
  }
}
