import {Component, Input, OnInit} from '@angular/core';
import {Page} from '../../models';

@Component({
  selector: 'app-page-card',
  templateUrl: './page-card.component.html',
  styleUrls: ['./page-card.component.css']
})
export class PageCardComponent implements OnInit {
  @Input() language = 'en';
  @Input() page: Page;

  constructor() { }

  ngOnInit() {
  }

}
