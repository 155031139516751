import {Injectable} from '@angular/core';
import {IoService} from './io.service';
import {Comparison} from '../models';

@Injectable({
  providedIn: 'root'
})
export class ComparisonService extends IoService {
  protected model = Comparison;
  protected listUrl = '/comparison/';
}
