import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-i18n',
  templateUrl: './i18n.component.html',
  styleUrls: ['./i18n.component.css']
})
export class I18nComponent implements OnInit {
  public language: string;
  public switchURL = '/';

  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.route.url.subscribe(segments => {
      this.switchURL = '/';
      this.language = segments[0].path;
      for (const segment of segments) {
        if (segment.path === 'en') {
          this.switchURL += 'fr';
        } else if (segment.path === 'fr') {
          this.switchURL += 'en';
        } else {
          this.switchURL += segment.path;
        }
        this.switchURL += '/';
      }
    });
  }

}
