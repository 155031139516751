// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card:nth-child(4n+1) {
  clear:left;
}
.card {
  display:inline-block;
  width:18em;
  margin:0 1em 1em 0;
  float:left;
}

.firestop.card {
  width:100%;
}
.results {
  padding:2em;
}
.none {
  font-size:1.5em;
}
.page.card {
  width:100%;
}
`, "",{"version":3,"sources":["webpack://./src/app/pages/search/search.component.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ;AACA;EACE,oBAAoB;EACpB,UAAU;EACV,kBAAkB;EAClB,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;AACA;EACE,WAAW;AACb;AACA;EACE,eAAe;AACjB;AACA;EACE,UAAU;AACZ","sourcesContent":[".card:nth-child(4n+1) {\n  clear:left;\n}\n.card {\n  display:inline-block;\n  width:18em;\n  margin:0 1em 1em 0;\n  float:left;\n}\n\n.firestop.card {\n  width:100%;\n}\n.results {\n  padding:2em;\n}\n.none {\n  font-size:1.5em;\n}\n.page.card {\n  width:100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
