// credit to http://jasonwatmore.com/post/2016/08/23/angular-2-pagination-example-with-logic-like-google
// modifications by MediaDoc Inc.
import {PageEvent} from '@angular/material/paginator';
import { Injectable } from "@angular/core";

@Injectable()
export class PagerService {
  /**
   * Calculate various pagination related properties. Check out Pager for all the calculated properties
   * @param {number} totalItems
   * @param {number} currentPage
   * @param {number} pageSize
   * @returns {Pager}
   */
  public getPager(totalItems: number, currentPage: number = 1, pageSize: number = 10) {
    // calculate total pages
    const totalPages = Math.ceil(totalItems / pageSize);

    let startPage: number, endPage: number;
    if (totalPages <= 10) {
      // less than 10 total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // more than 10 total pages so calculate start and end pages
      if (currentPage <= 6) {
        startPage = 1;
        endPage = 10;
      } else if (currentPage + 4 >= totalPages) {
        startPage = totalPages - 9;
        endPage = totalPages;
      } else {
        startPage = currentPage - 5;
        endPage = currentPage + 4;
      }
    }

    // calculate start and end item indexes
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

    // create an array of pages to ng-repeat in the pager control
    const pages = Array();
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    // return object with all pager properties required by the view
    const pager = new Pager();
    pager.totalItems = totalItems;
    pager.currentPage = currentPage;
    pager.pageSize = pageSize;
    pager.totalPages = totalPages;
    pager.startPage = startPage;
    pager.endPage = endPage;
    pager.startIndex = startIndex;
    pager.endIndex = endIndex;
    pager.pages = pages;
    return pager;
  }

  /**
   * Based on the given PageEvent, determine what action the user took. ie. selected the "next" button
   * @link https://material.angular.io/components/paginator/api#PageEvent
   * @param {PageEvent} previousPageEvent
   * @param {PageEvent} nextPageEvent
   * @returns {PageEventChanges}
   */
  public calculatePageChange(previousPageEvent: PageEvent, nextPageEvent: PageEvent) {
    const changes = new PageEventChanges();
    if (nextPageEvent.pageIndex - previousPageEvent.pageIndex === 1) {
      changes.isNextPage = true;
    }
    if (nextPageEvent.pageIndex - previousPageEvent.pageIndex === -1) {
      changes.isPreviousPage = true;
    }
    return changes;
  }
}

export class Pager {
  totalItems: number;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  startPage: number;
  endPage: number;
  startIndex: number;
  endIndex: number;
  pages: number[];
}

export class PageEventChanges {
  isNextPage = false;
  isPreviousPage = false;
}

export class Pagination {
  count: number;
  page_size: number;
  next: string;
  previous: string;
  results: Array<any>;

  public constructor(data: {}) {
    Object.keys(data).forEach(key => {
      this[key] = data[key];
    });
  }
}
