import {Component} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SiteInfoService} from './services/site-info.service';

declare let gtag: (action: string, id: string, settings: {}) => void;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'nuco-ng';

  constructor(private router: Router, private siteInfoService: SiteInfoService) {
    // @ts-ignore
    router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationEnd) {
        this.sendGoogleAnalytics(event);
      }
    });
  }

  private sendGoogleAnalytics(event: NavigationEnd) {
    this.siteInfoService.list().subscribe(info => {
      const siteInfo = info[0];
      if (siteInfo.google_analytics_id) {
        const location = window.location;
        const u = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
        gtag('config', siteInfo.google_analytics_id, {
          page_path: event.urlAfterRedirects,
          page_location: u + event.urlAfterRedirects
        });
      }
    });
  }
}
