import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError, forkJoin} from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import {HttpOption} from '../options';
import {Pagination} from './pager.service';
import {environment} from '../../environments/environment';

@Injectable()
export class IoService {
  public APIURL = environment.baseUrl;
  public slug : string = environment.slug;
  protected model;
  protected listUrl;
  protected sharedSubscriptions: {key: string, observable: Observable<any>}[] = [];

  constructor(
    protected http: HttpClient
  ) {}

  static convertObjectsToModel(model: any, items: any) {
    const objects = Array();
    for (const item of items) {
      const object = new model();
      Object.keys(item).forEach(key => {
        object[key] = item[key];
      });
      objects.push(object);
    }
    return objects;
  }

  static checkIfObjectHasUrl(object: any, throwErrorWhen: boolean) {
    const hasUrl = ('url' in object && object['url'] !== undefined);
    if (hasUrl === throwErrorWhen) {
      throw new Error('checkIfObjectHasUrl failed. Make sure you are using the correct HTTP crud method');
    }
  }

  static cleanObject(object: any) {
    const removeProperties = ['objects', 'deleting', 'is_selected'];
    for (const prop of removeProperties) {
      const hasProp = (prop in object && object[prop] !== typeof 'undefined');
      if (hasProp) {
        delete object[prop];
      }
    }
    return object;
  }

  static getIdFromUrl(url: string): string {
    const pieces = url.split('/');
    return pieces[pieces.length - 2];
  }

  static getIdFromObject(obj: any): string {
    if (obj && obj.url) {
      return IoService.getIdFromUrl(obj.url);
    }
  }

  static handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      return throwError('Something bad happened; please try again later.');
    } else {
      // The backend returned an unsuccessful response code. Return the error to the service subscribing to handle it accordingly
      return throwError(error.error);
    }
  }

  public list(filters = {}): Observable<any> {
    const url = this.APIURL + this.listUrl;
    const params = this.generateParams(filters);

    const obs = this.http.get(url + params).pipe(map(items => {
      if (filters && Object.keys(filters).indexOf('page') > -1) {
        items['results'] = IoService.convertObjectsToModel(this.model, items['results']);
        return new Pagination(items);
      } else {
        return IoService.convertObjectsToModel(this.model, items);
      }
    }));
    return obs;
  }

  public get(id: string, filters = {}): Observable<any> {
    const params = this.generateParams(filters);
    const url = this.APIURL + this.listUrl + id;
    return this.http.get(url + params).pipe(map(item => {
      return IoService.convertObjectsToModel(this.model, [item])[0];
    }));
  }

  public getByUrl(url, filters = {}): Observable<any> {
    const params = this.generateParams(filters);
    return this.http.get(url + params).pipe(map(item => {
      return IoService.convertObjectsToModel(this.model, [item])[0];
    }));
  }

  public getMultiple(urls: Array<string>): Observable<Array<any>> {
    const calls = Array();
    for (const url of urls) {
      calls.push(this.get(url));
    }
    return forkJoin(calls).pipe(map(items => {
      return IoService.convertObjectsToModel(this.model, items);
    }));
  }

  public create(object: any, additionalHeaders = {}) {
    IoService.checkIfObjectHasUrl(object, true);
    object = IoService.cleanObject(object);
    return this.http.post(this.APIURL + this.listUrl, object, {headers: new HttpHeaders(additionalHeaders)}).pipe(
      map(response => {
        try {
          return IoService.convertObjectsToModel(this.model, [response])[0];
        } catch (e) {
          // this happens if `object` is not an object from the models
          console.log('create mapping error:');
          console.log(object);
          console.log(e);
          return response;
        }
      }),
      catchError(IoService.handleError)
    );
  }

  public update(object: any, additionalHeaders = {}) {
    IoService.checkIfObjectHasUrl(object, false);
    object = IoService.cleanObject(object);
    return this.http.patch(object.url, object, {headers: new HttpHeaders(additionalHeaders)}).pipe(
      map(response => {
        try {
          return IoService.convertObjectsToModel(this.model, [response])[0];
        } catch (e) {
          // this happens if `object` is not an object from the models
          console.log('update mapping error:');
          console.log(object);
          console.log(e);
          return response;
        }
      }),
      catchError(IoService.handleError)
    );
  }

  public delete(object: any, additionalHeaders = {}) {
    IoService.checkIfObjectHasUrl(object, false);
    return this.http.delete(object.url, {headers: new HttpHeaders(additionalHeaders)}).pipe(catchError((IoService.handleError)));
  }

  public options(url: string): Observable<HttpOption> {
    return this.http.options(url).pipe(map(o => new HttpOption(o)));
  }

  public cloneProperties(source: any, dest: any) {
    // clone the properties of source into dest
    // this does not remove any extra properties on dest
    Object.keys(source).forEach(key => {
      dest[key] = source[key];
    });
    return dest;
  }

  protected generateParams(filters: {}) {
    let params = "?site=" + environment.slug;
    if (filters && Object.keys(filters).length > 0) {
      params += '&';
      for (const key in filters) {
        if (filters.hasOwnProperty(key)) {
          const value = filters[key];
          params += key + '=' + value + '&';
        }
      }
      params = params.slice(0, -1);
    } else {
      params = '';
    }
    return params;
  }
}
