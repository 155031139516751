import {Injectable} from '@angular/core';
import {IoService} from './io.service';
import {Media} from '../models';

@Injectable()
export class MediaService extends IoService {
  protected model = Media;
  protected listUrl = '/media/';

}
